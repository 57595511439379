import {BOX_SHADOW} from 'core/styles';
import styled from 'styled-components';

export let container = (props) => {
    return Object.assign(
        {
            backgroundColor: '#fff',
            boxShadow: BOX_SHADOW,
            padding: '30px 30px 15px 30px',
            marginBottom: '15px',
            position: 'relative',
        },
        props.style,
    );
};

export let ContainerStyled = styled.div`
    border-radius: 16px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 32px 24px;
    margin-bottom: 16px;
    position: relative;
    ${(props) => (props.$css ? props.$css : '')};
`;
